@import "general/colors";
@import "general/newTypography";
@import "general/transitions";

$green-500: #22c55e;
$green-700: #15803d;
$lightBlue-500: #00c0eb;
$lightBlue-700: #0a7194;
$yellow-500: #ffc70e;
$yellow-700: #a37d00;
$blue-500: #3b82f6;
$blue-900: #1e3a8a;

.new-project-description {
  padding: 6em 0 7em 0;
  .title-area {
    @include title-area;
    text-align: left;
    color: $new-blue;
    border-left: solid 6px $new-yellow;
    padding: 0.5em 1em;
    padding-bottom: 0;
    margin-left: 5em;
    @media screen and (max-width: 1200px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 650px) {
      margin-left: 0em;
    }
  }
  .subtitle-area {
    @include subtitle-area;
    text-align: left;
    color: $new-blue;
    padding: 0.5em 1em;
    margin-left: 5em;
    margin-bottom: 2.5em;
    @media screen and (max-width: 1200px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 650px) {
      margin-left: 0em;
    }
  }
  .cards-project-description {
    display: flex;
    gap: 2.5em;
    justify-content: center;
    flex-wrap: wrap;
    .card-project-description {
      transition: box-shadow 1s, background-color 1s, opacity 1s;
      padding: 3em 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: calc(100% / 4);
      border-radius: 25px;
      max-width: 16em;
      @media screen and (max-width: 1100px) {
        width: calc(100% / 3);
      }
      @media screen and (max-width: 740px) {
        width: calc(100% / 2);
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      .image {
        width: 80px;
        height: 80px;
        margin-bottom: 2em;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .text-card {
        font-family: $ubuntu-typography;
        font-weight: bold;
        font-size: 21px;
        text-align: center;
        line-height: 1em;
      }
      &:hover {
        .text-card {
          color: #ffffff;
        }
        &.from-green-500 {
          background: linear-gradient(to right, $green-500, $green-700);
        }

        &.from-lightBlue-500 {
          background: linear-gradient(to right, $lightBlue-500, $lightBlue-700);
        }

        &.from-yellow-500 {
          background: linear-gradient(to right, $yellow-500, $yellow-700);
        }

        &.from-blue-500 {
          background: linear-gradient(to right, $blue-500, $blue-900);
        }
      }
      &.shadow-green-500 {
        box-shadow: 0 4px 6px $green-500;
        &:hover {
          box-shadow: 0 8px 15px $green-500;
        }
      }

      &.shadow-lightBlue-500 {
        box-shadow: 0 4px 6px $lightBlue-500;
        &:hover {
          box-shadow: 0 8px 15px $lightBlue-500;
        }
      }

      &.shadow-yellow-500 {
        box-shadow: 0 4px 6px $yellow-500;
        &:hover {
          box-shadow: 0 8px 15px $yellow-500;
        }
      }

      &.shadow-blue-500 {
        box-shadow: 0 4px 6px $blue-500;
        &:hover {
          box-shadow: 0 8px 15px $blue-500;
        }
      }
    }
  }
}
